import React from "react"
import { useCookies } from 'react-cookie'
import { useLocation } from '@reach/router'
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import LrButton from "../components/lrButton"
import LangSwitch from "../components/langSwitch"

const Index = ({ data: { icon } }) => {
  const { formatMessage } = useIntl()
  const location = useLocation()
  const [, setCookie] = useCookies()
  setCookie('captive_portal', location.search, { maxAge: 3600 })

  return (
    <Layout className="lr-homepage" isLight icon={icon}>
      <SEO title={formatMessage({ id: 'benvenuti_in_cansiglio' })} />
      <div className="lr-homepage__lang my-8">
        <LangSwitch />
      </div>
      <div className="lr-homepage__title text-center">
        <h1 className="font-dancing text-3xl">
          <FormattedMessage id="casera_le_rotte" />
        </h1>
        <h2 className="font-dancing text-6xl text-shadow leading-none">
          <FormattedMessage id="benvenuti_in_cansiglio" />
        </h2>
      </div>
      <div className="lr-homepage__main mt-12 mb-16">
        <div className="text-xl font-light italic tracking-wider text-center px-6">
          <FormattedMessage id="main_index_content" />
        </div>
      </div>
      <div className="lr-homepage__buttons flex flex-col items-center">
        {process.env.GATSBY_WIFI_ACTIVE && process.env.GATSBY_WIFI_ACTIVE !== 'false' && (
          <Link to="/wifi/" className="block mb-8">
            <LrButton>
              <FormattedMessage id="accedi_wifi" />
            </LrButton>
          </Link>
        )}
        <Link to="/menu/" className="block">
          <LrButton>
            <FormattedMessage id="sfoglia_menu" />
          </LrButton>
        </Link>
      </div>
    </Layout>
  )
}

export const query = graphql`
query HomePageImage {
  icon: file(relativePath: { eq: "casette.png" }) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default Index
